@import '~antd/dist/antd.css';

* {
  margin: 0px;
  padding: 0px;
}

body {
  font: 300 1em/1 'Open Sans', sans-serif !important;
  background-color: rgba(10, 9, 9, 0.72);
  width: auto;
  height: auto;
}

/* Action Button


*/

.actionButton {
  bottom: 0;
  position: fixed;
  margin: 1em;
  right: 0px;
  cursor: pointer;
}

.buttons {
  box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.18),
    0px 4px 12px -7px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: block;
  width: 56px;
  height: 56px;
  margin: 20px auto 0;
  position: relative;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  cursor: pointer;
}

.buttons:active,
.buttons:focus,
.buttons:hover {
  border: none !important;
  outline: none !important;
}

.buttons:not(:last-child) {
  width: 40px;
  height: 40px;
  margin: 20px auto 0;
  opacity: 0;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}

.actionButton:hover .buttons:not(:last-child) {
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin: 15px auto 0;
  border: none !important;
  outline: none !important;
}

/* Unessential styling for sliding up buttons at differnt speeds */

.buttons:nth-last-child(1) {
  -webkit-transition-delay: 25ms;
  transition-delay: 25ms;
  background-color: #21c13c;
  background-size: contain;
}

.buttons:not(:last-child):nth-last-child(2) {
  -webkit-transition-delay: 50ms;
  transition-delay: 20ms;
  background-color: #585858;
  background-size: contain;
}

.buttons:not(:last-child):nth-last-child(2):hover {
  transform: scale(1.1);
}

.buttons:not(:last-child):nth-last-child(3) {
  -webkit-transition-delay: 75ms;
  transition-delay: 40ms;
  background-color: #585858;
  background-size: contain;
}

.buttons:not(:last-child):nth-last-child(3):hover {
  transform: scale(1.1);
}

.buttons:not(:last-child):nth-last-child(4) {
  -webkit-transition-delay: 100ms;
  transition-delay: 60ms;
  background-color: #585858;
  background-size: contain;
}

.buttons:not(:last-child):nth-last-child(4):hover {
  transform: scale(1.1);
}

.buttons:not(:last-child):nth-last-child(5) {
  -webkit-transition-delay: 125ms;
  transition-delay: 80ms;
  background-color: #585858;
  background-size: contain;
}

.buttons:not(:last-child):nth-last-child(5):hover {
  transform: scale(1.1);
}

.buttons:not(:last-child):nth-last-child(6) {
  -webkit-transition-delay: 150ms;
  transition-delay: 100ms;
  background-color: #585858;
  background-size: contain;
}

.buttons:not(:last-child):nth-last-child(6):hover {
  transform: scale(1.1);
}

.buttons:not(:last-child):nth-last-child(7) {
  -webkit-transition-delay: 175ms;
  transition-delay: 120ms;
  background-color: #585858;
  background-size: contain;
}

.buttons:not(:last-child):nth-last-child(7):hover {
  transform: scale(1.1);
}
/* Show tooltip content on hover */

[tooltip]:before {
  bottom: 25%;
  font-family: arial;
  font-weight: 600;
  border-radius: 2px;
  background: #585858;
  color: #fff;
  content: attr(tooltip);
  font-size: 12px;
  visibility: hidden;
  opacity: 0;
  padding: 5px 7px;
  margin-right: 12px;
  position: absolute;
  right: 100%;
  white-space: nowrap;
}

[tooltip]:hover:before,
[tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
/* ROW & COLUMN CSS */
#canvas-wrapper {
  box-sizing: border-box;
  margin: 5vh 2.5vw 5vh 2.5vw;
  margin-top: 5vh;
  width: 95vw;
  height: 90vh;
  background-color: white;
  -webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.5);
}

.row {
  box-sizing: border-box;
  background-color: #ffffff;
  height: 60vh;
  width: 19vw;
  float: left;
  border-bottom: 2px solid rgba(10, 9, 9, 0.72);
}

.row-1 {
  border-right: 2px solid rgba(10, 9, 9, 0.72);
}

.row-2,
.row-3,
.row-4 {
  border-left: 2px solid rgba(10, 9, 9, 0.72);
  border-right: 2px solid rgba(10, 9, 9, 0.72);
}

.row-5 {
  border-left: 2px solid rgba(10, 9, 9, 0.72);
}

.full-box {
  width: 100%;
  height: 60vh;
  float: left;
}
.full-box .text-area {
  border: none;
  box-sizing: border-box;
  height: 54.2vh;
  resize: none;
  width: 100%;
  overflow-y: auto;
  padding: 0.5vh 0.75vw 0.5vh 0.75vw;
  float: left;
  outline: none;
}

.half-box-top {
  border-bottom: 2px solid rgba(10, 9, 9, 0.72);
  height: 30vh;
  width: 100%;
  float: left;
}
.half-box-top .text-area {
  border: none;
  box-sizing: border-box;
  height: 24.3vh;
  resize: none;
  width: 100%;
  overflow-y: auto;
  padding: 0.5vh 0.75vw 0.5vh 0.75vw;
  float: left;
  outline: none;
}

.half-box-bottom {
  border-top: 2px solid rgba(10, 9, 9, 0.72);
  height: 30vh;
  width: 100%;
  float: left;
}
.half-box-bottom .text-area {
  border: none;
  box-sizing: border-box;
  height: 24vh;
  resize: none;
  width: 100%;
  overflow-y: auto;
  padding: 0.5vh 0.75vw 0.5vh 0.75vw;
  float: left;
  outline: none;
}

.column {
  border-top: 2px solid rgba(10, 9, 9, 0.72);
  box-sizing: border-box;
  background-color: #ffffff;
  height: 30vh;
  width: 47.5vw;
  float: left;
}
.column .text-area {
  border: none;
  box-sizing: border-box;
  height: 24vh;
  resize: none;
  width: 100%;
  overflow-y: auto;
  padding: 0.5vh 0.75vw 0.5vh 0.75vw;
  float: left;
  outline: none;
}

.column-1 {
  border-right: 2px solid rgba(10, 9, 9, 0.72);
}

.column-2 {
  border-left: 2px solid rgba(10, 9, 9, 0.72);
}

.box-header {
  box-sizing: border-box;
  width: 100%;
  height: 5vh;
  background-color: #eeeeee;
  border-bottom: 1px solid #cccccc;
}
.box-header h2 {
  float: left;
  margin-left: 2vw;
  font-weight: 400;
  line-height: 5vh;
  font-size: 0.94em;
}
.box-header i {
  float: left;
  margin-left: 1vw;
  line-height: 5vh;
  color: rgba(10, 9, 9, 0.72);
  width: 1vw;
}

.hideOnMobile {
  display: inherit;
}

/* RESIZE FOR TABLET & PHONE */
@media (max-width: 1000px) {
  #canvas-wrapper {
    width: 95vw;
    margin-left: 0;
    margin-right: 0;
    height: 140vh;
    margin: auto;
    margin-top: 5vh;
  }

  .row {
    box-sizing: border-box;
    width: 95vw;
    height: 20vh;
    border: none;
  }

  .half-box-bottom {
    box-sizing: border-box;
    width: 47.5vw;
    height: 20vh;
    border: none;
    border-left: 1px solid rgba(10, 9, 9, 0.72);
  }

  .half-box-top {
    box-sizing: border-box;
    width: 47.5vw;
    height: 20vh;
    border: none;
    border-right: 1px solid rgba(10, 9, 9, 0.72);
  }

  .column {
    width: 95vw;
    height: 20vh;
    border: none;
  }

  .text-area {
    height: 15vh !important;
  }

  .box-header h2 {
    margin-left: 5vw;
    /* width: 40vw; */
  }
  .box-header i {
    width: 2vw;
  }
}

@media (max-width: 720px) {
  .hideOnMobile {
    display: none;
  }

  .text-area {
    height: 14vh !important;
  }
}

/*# sourceMappingURL=model-style.css.map */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* for the visitiablity dropdown selction */
.ant-select-selection {
  width: 182px;
}

#shared_permissions_dropdown .ant-select-selection {
  width: 136px !important;
}

#shared_permissions_actionType_dropdown .ant-select-selection {
  width: 60px !important;
}

#canvas-wrapper .ant-input {
  border-radius: 0px;
}

#canvas-wrapper .ant-input:focus {
  box-shadow: none;
}
